import React from 'react'

import '../styles/skill-level.css'

export default function SkillLevel({ level=1 }) {
  return (
    <div id='skill-level-wrapper'>
        <div style={{width: `${level * 25}%`}} id='skill-level-bar' />
    </div>
  )
}
