import React from 'react'

import '../styles/lang-switch.css'

const StateStyle = {
    'en': {left: "3px"},
    'fa': {left: "36px"}
}

export default function LangSwitch({lang, switchLang}) {
    return (
    <div className='switch-wrapper'>
        <div
            className='switch-body'
            onClick={switchLang}
        >
            <span className='switch-label'>فا</span>
            <span className='switch-label'>En</span>
            <div
                className='switch-button'
                style={StateStyle[lang]}
            />
        </div>
    </div>
    )
}
