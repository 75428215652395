import { FaDocker, FaGit, FaJs, FaNodeJs, FaPython, FaReact } from 'react-icons/fa6'
import { SiPytorch, SiTensorflow } from 'react-icons/si'
import { LuBrainCircuit } from 'react-icons/lu'
import { GiCircuitry } from 'react-icons/gi'
import { BsCpu } from 'react-icons/bs'

const skills = [
    {id: 8, icon: LuBrainCircuit, name: "AI/ML", level: 3},
    {id: 5, icon: SiTensorflow, name: "Tensorflow", level: 3},
    {id: 6, icon: SiPytorch, name: "Pytorch", level: 2},
    {id: 0, icon: FaJs, name: "Javascript", level: 3},
    {id: 2, icon: FaNodeJs, name: "NodeJS", level: 3},
    {id: 3, icon: FaPython, name: "Python", level: 3},
    {id: 4, icon: FaReact, name: "ReactJS", level: 3},
    {id: 1, icon: FaDocker, name: "Docker", level: 2},
    {id: 10, icon: FaGit, name: "Git", level: 3},
    {id: 7, icon: GiCircuitry, name: "Circuit Design", level: 2},
    {id: 9, icon: BsCpu, name: "Microcontroller", level: 2},
]

export default skills;