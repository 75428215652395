import { createContext, useEffect, useState } from 'react';
import { TopBar } from './components/topbar';
import Landing from './pages/landing';
import ProjectsPage from './pages/projects';

import './App.css';
import ScrollToTopButton from './components/scroll-to-top';
import AboutMe from './pages/about';
import Contact from './pages/contact';
import Skills from './pages/skills';
import Footer from './pages/footer';

export const langCtx = createContext()


window.addEventListener('scroll', ()=>{
  if (window.scrollY === 0) {

  }
})

function App() {
  const [lang, setLang] = useState('en')
  const [isAtTop, setAtTop] = useState(true)
  useEffect(() => {
    const checkScroll = ()=>{
      if (window.scrollY === 0 && !isAtTop) {
        setAtTop(true)
      } else if (isAtTop) {
        setAtTop(false)
      }
    }
    window.addEventListener('scroll', checkScroll)
    return () => {window.removeEventListener('scroll', checkScroll)}
  }, [isAtTop])
  const switchLang = () => {
    setLang(prev => prev === 'fa' ? 'en' : 'fa')
  }
  return (
    <div className="App">
      <langCtx.Provider value={lang}>
        <TopBar dir={lang==='fa' ? 'rtl' : 'ltr'} switchLang={switchLang} />
        <Landing />
        <ProjectsPage />
        <AboutMe />
        <Contact />
        <Skills />
        <Footer />
      </langCtx.Provider>
      {!isAtTop && <ScrollToTopButton />}
    </div>
  );
}

export default App;
