import React, { useContext } from 'react'

import skills from '../utils/skills'
import SkillLevel from '../components/skill-level'
import strings from '../strings.json'

import '../styles/skills-page.css'
import { langCtx } from '../App'

export default function Skills() {
    const lang = useContext(langCtx)
    return (
    <div className='page' id='skills'>
        <h1 className='page-title'>{strings[lang].skills}</h1>
        <div id='skills-container'>
            {skills.map(skill => {
                return (
                    <div className='skill-item' key={skill.id}>
                        <p>{skill.name}</p>
                        <span>{React.createElement(skill.icon, {size: 18})}</span>
                        <div>
                            <SkillLevel level={skill.level} />
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
    )
}
