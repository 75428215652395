import React from 'react'

import '../styles/project-modal.css'
import { FaX } from 'react-icons/fa6'

export default function ProjectModal({ title, children, closeHandler }) {
  return (
    <div id='modal-wrapper' onClick={closeHandler}>
        <div id='modal-container' onClick={(e)=>{e.stopPropagation();}}>
            <div id='modal-title'>
                <p>{title}</p>
                <span onClick={closeHandler} id='modal-close'><FaX /></span>
            </div>
            <div id='modal-content'>
                {children}
            </div>
        </div>
    </div>
  )
}
