import React, { useContext } from 'react';

import { langCtx } from '../App';

import '../styles/topbar.css';
import strings from '../strings.json';
import LangSwitch from './lang-switch';

export const TopBar = ({dir="ltr", switchLang}) => {
    const lang = useContext(langCtx)
    return (
        <div className='topbar-wrapper'>
            <div className='topbar-menu' dir={dir}>
                <a className='topbar-item' href='#projects'>{strings[lang].projects}</a>
                <a className='topbar-item' href='#contact'>{strings[lang].contact}</a>
                <a className='topbar-item' href='#about'>{strings[lang].about}</a>
                <a className='topbar-item' href='#skills'>{strings[lang].skills}</a>
                <p id='topbar-name'>{strings[lang].name}</p>
            </div>
            <LangSwitch lang={lang} switchLang={switchLang} />
        </div>
    )
}