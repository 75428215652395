import React, { useContext } from 'react'

import strings from '../strings.json'
import { langCtx } from '../App'
import shape from '../assets/shape.svg'

import '../styles/page.css'
import '../styles/about.css'

import SkillImage from '../components/skill-image'
import AutoCarousel from '../components/auto-carousel'
import skills from '../utils/skills'

export default function AboutMe() {
    const lang = useContext(langCtx)
    return (
    <div className='about-me-page-wrapper page' dir={lang === 'fa' ? 'rtl' : 'ltr'}>
        <div className='page-content' id='about-me-content'>
            <h1 className='page-title' id='about'>{strings[lang].about}</h1>
            {strings[lang].about_me.map(paragraph => (
                <p>{paragraph}</p>  
            ))}
        </div>
        <div id='about-me-carousel'>
            <div style={{height: '50px', width: '660px'}}>
                <AutoCarousel items={skills} />
            </div>
        </div>
        <div id='about-me-side'>
            <div id='about-me-skills'>
                <SkillImage img={skills[0].icon} pos={{left: '15%', top: '35%'}} />
                <SkillImage img={skills[1].icon} pos={{left: '40%', top: '50%'}} />
                <SkillImage img={skills[2].icon} pos={{left: '50%', top: '35%'}} />
                <SkillImage img={skills[3].icon} pos={{left: '70%', top: '45%'}} />
                <SkillImage img={skills[4].icon} pos={{left: '20%', top: '55%'}} />
                <SkillImage img={skills[5].icon} pos={{left: '35%', top: '30%'}} />
                <SkillImage img={skills[6].icon} pos={{left: '55%', top: '55%'}} />
                <SkillImage img={skills[7].icon} pos={{left: '75%', top: '30%'}} />
                <SkillImage img={skills[8].icon} pos={{left: '80%', top: '60%'}} />
                <SkillImage img={skills[9].icon} pos={{left: '40%', top: '65%'}} />
                <SkillImage img={skills[10].icon} pos={{left: '65%', top: '65%'}} />
            </div>
            <div id='about-me-shape'>
                <img src={shape} alt='' width="300px" />
            </div>
        </div>
    </div>
    )
}
