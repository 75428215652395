import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa6'

export default function SocialButton({type}) {
  return (
    <div className='social-button-wrapper'>
        <a target='blank' href={type === 'linkedin' ? 'https://www.linkedin.com/in/rahiminia' : 'https://github.com/Rahiminia'}>            
          {type==='linkedin' && <FaLinkedin size='40px'/>}
          {type==='github' && <FaGithub size='40px'/>}
        </a>
    </div>
  )
}
