import React from 'react'
import Button from './button'

import '../styles/project-card.css'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { FaVideo } from 'react-icons/fa6'
import ProjectTag from './project-tag'

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export default function ProjectCard({title, desc, thumbnail, demoLink, githubLink, projectLink, showModal, tags}) {
  return (
    <div className='project-card'>
        <img src={thumbnail} alt='Project' className='project-image'/>
        <div className='project-title'>{title}</div>
        <div dir='ltr' style={{display: 'flex', width: '100%', gap: '5px', marginBottom: '10px', marginLeft: '40px', alignItems: 'flex-start'}}>
          {tags.map(tag => (
            <ProjectTag key={tag} name={tag} />
          ))}
        </div>
        <div className='project-desc'>{desc}</div>
        <div className='project-card-buttons' dir='ltr'>
        {
          demoLink && (<Button
                title="Demo"
                clickHandler={() => {showModal(demoLink)}}
                style={{
                  width: "95px",
                  height: "32px",
                  margin: "0 10px 0 0",
                  fontSize: "0.9em",
                  paddingTop: "3px",
                }}
                icon={FaVideo}
                lang='en'
            />)
          }
          {githubLink && (<Button
                title="Github"
                clickHandler={() => {openInNewTab(githubLink)}}
                style={{
                  width: "95px",
                  height: "32px",
                  fontSize: "0.9em",
                  paddingTop: "3px",
                }}
                bordered
                lang='en'
            />)
          }
          {projectLink && (<Button
                title="Project"
                clickHandler={() => {openInNewTab(projectLink)}}
                style={{
                  width: "95px",
                  height: "32px",
                  fontSize: "0.9em",
                  paddingTop: "3px",
                }}
                icon={FaExternalLinkAlt}
                bordered
                lang='en'
            />)
          }
        </div>
    </div>
  )
}
