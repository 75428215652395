import React, { useContext, useState } from 'react'
import { langCtx } from '../App'

import ProjectCard from '../components/project-card'
import projects from '../projects.json'
import strings from '../strings.json'
import { images } from '../utils/image-url'
import ProjectModal from '../components/project-modal'
import Video from '../components/video-player'

import '../styles/page.css'
import '../styles/projects.css'

export default function ProjectsPage() {
  const lang = useContext(langCtx)
  const [modalStatus, setModalStatus] = useState({show: false, url: null})
  const closeModal = () => {
    setModalStatus({show: false, url: null})
  }
  const openModal = (url) => {
    setModalStatus({show: true, url})
  }
  return (
    <div className='projects-wrapper page' id='projects' dir={lang === 'fa' ? 'rtl' : 'ltr'}>
        <h1 className='page-title'>{strings[lang].projects}</h1>
        {modalStatus.show && (
          <ProjectModal closeHandler={closeModal} title={modalStatus.url}>
            <Video url={`/videos/${modalStatus.url}`}/>
          </ProjectModal>
        )}
        <div className='projects-list'>
            {projects.map(item => (
              <ProjectCard
                key={item.title}
                thumbnail={images[item.thumbnail]}
                title={item.title}
                desc={item[`desc-${lang}`]}
                demoLink={item.demoUrl}
                githubLink={item.githubUrl}
                projectLink={item.projectUrl}
                tags={item.tags}
                showModal={(url) => {openModal(url)}}
              />
            ))}
        </div>
    </div>
  )
}
