import React from 'react'

import '../styles/auto-carousel.css'

export default function AutoCarousel({ items }) {
  return (
    <div id='carousel-container'>
        <div id='carousel-track'>
            {items.map(item => (<div key={item.id}>{React.createElement(item.icon, {size: '40'})}</div>))}
            {items.map(item => (<div key={item.id}>{React.createElement(item.icon, {size: '40'})}</div>))}
        </div>
    </div>
  )
}
