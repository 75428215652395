import React from 'react'

import '../styles/skill-image.css'

export default function SkillImage({ img, pos= {left: 0, top: 0} }) {
    return (
    <div
        className='skill-img-wrapper'
        style={{
            left: pos.left,
            top: pos.top,
        }}
    >
        {React.createElement(img)}
    </div>
    )
}
