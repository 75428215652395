import '../styles/video-player.css'

const Video = ({ url, type="video/mp4", height="100%" }) => {
    return (
      <video controls height={height} autoPlay>
        <source src={url} type={type} />
        Sorry, your browser doesn't support videos.
      </video>
    );
  };
  
  export default Video;