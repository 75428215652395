import React from 'react'
import { FaArrowUp } from "react-icons/fa6";

import '../styles/stt-button.css'

export default function ScrollToTopButton() {
  return (
    <div className='stt-wrapper' onClick={() => {window.scrollTo({top: 0})}}>
        <FaArrowUp />
    </div>
  )
}
