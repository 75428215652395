import React, { useContext } from 'react'

import { langCtx } from '../App'

import '../styles/landing.css'
import profilePic from '../assets/pic.png'
import bgImage from '../assets/bg.svg'
import strings from '../strings.json'
import Button from '../components/button'
import SocialButton from '../components/social-button'
import { FaDownload, FaEnvelope } from 'react-icons/fa6'
import { FaChevronDown } from 'react-icons/fa'

export default function Landing() {
    const lang = useContext(langCtx)
    return (
        <div className='main-wrapper' id='main'>
            <img id='bg-img' src={bgImage} alt='' />
            <div id='pic-wrapper'>
                <img id='pic' src={profilePic} alt='Hassan Rahimi'/>
            </div>
            <div style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
                <div className='landing-title'>
                    <h1>{strings[lang].name}</h1>
                    <h2>{strings[lang].main_skills}</h2>
                </div>
                <div style={{display: 'flex'}}>
                    <a
                        href='files/Rahiminia-Resume.pdf'
                        download='Rahiminia-Resume.pdf'
                        style={{all: 'unset'}}
                    >
                        <Button
                            title={strings[lang].dl_cv}
                            icon={FaDownload}
                            style={{
                                paddingTop: lang==='en' ? '5px' : '0',
                                margin: "10px 5px 20px 5px",
                                backgroundColor: '#5474cc',
                                borderColor: '#5474cc',
                                color: 'white',
                            }}
                            lang={lang}
                        />
                    </a>
                    <Button
                        title={strings[lang].contact_me}
                        icon={FaEnvelope}
                        style={{
                            paddingTop: lang==='en' ? '5px' : '0',
                            margin: "10px 5px 20px 5px",
                        }}
                        bordered
                        lang={lang}
                        clickHandler={() => {window.location.replace('#contact')}}
                    />
                </div>
                <div className='socials'>
                    <SocialButton type='github' />
                    <SocialButton type='linkedin' />
                </div>
            </div>
            <a id='arrow-down-wrapper' href='#projects'>
                <FaChevronDown size={25} />
            </a>
        </div>
    )
}
