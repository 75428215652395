import React from 'react'

import '../styles/button.css'

export default function Button({title, style={}, bordered=false, clickHandler, icon=null, lang}) {
  return (
    <div
      className={`button ${bordered && 'bordered'}`}
      style={style}
      onClick={clickHandler}

    >
      {lang === 'en' && <div className='button-icon en'>
        {icon && React.createElement(icon, {size: 12})}
      </div>}
      <p>{title}</p>
      {lang === 'fa' && <div className='button-icon fa'>
        {icon && React.createElement(icon, {size: 12})}
      </div>}
    </div>
  )
}
