import React, { useContext } from 'react'

import strings from '../strings.json'
import { langCtx } from '../App'

import '../styles/contact.css'
import { FaDownload, FaEnvelope, FaPhone, FaUser } from 'react-icons/fa6'
import { FaExternalLinkAlt } from 'react-icons/fa'

export default function Contact() {
    const lang = useContext(langCtx)
  return (
    <div className='contact-page-wrapper' id='contact'>
        <div id='contact-container'>
            <h1 id='contact-title'>{strings[lang].contact_me}</h1>
            <div id='contact-items'>
                <div className='contact-item'>
                    <span className='contact-icon'>
                        <FaEnvelope />
                    </span>
                    <h2>Email</h2>
                    <a href='mailto:rahiminia.ai@gmail.com'>rahiminia.ai@gmail.com</a>
                </div>
                <div className='contact-item'>
                    <span className='contact-icon'>
                        <FaPhone />
                    </span>
                    <h2>Tel</h2>
                    <a href='tel:+98937-675-4492'>+98 937-675-4492</a>
                </div>
                <div className='contact-item'>
                    <span className='contact-icon'>
                        <FaUser />
                    </span>
                    <h2>Links</h2>
                    <a href='https://www.linkedin.com/in/rahiminia' className='underline' target='blank'>
                        <span>LinkedIn Account </span>
                        <FaExternalLinkAlt size={15}/>
                    </a>
                    <a href='https://github.com/Rahiminia' className='underline' target='blank'>
                        <span>Github Account </span>
                        <FaExternalLinkAlt size={15}/>
                    </a>
                    <a href='files/Rahiminia-Resume.pdf' download='Rahiminia-Resume.pdf' className='underline' target='blank'>
                        <span>My Resume </span>
                        <FaDownload size={15}/>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}
