import React from 'react'

import '../styles/footer.css'

export default function Footer() {
  return (
    <div id='footer-container'>
        <div id='footer-links'>
            <a href='#projects'>Projects</a>
            <a href='#contact'>Contact</a>
            <a href='#about'>About</a>
        </div>
        <p id='footer-text'>2024 | Designed by <strong>Hassan Rahimi</strong> with 🤍</p>
    </div>
  )
}
