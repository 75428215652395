import React from 'react'

import '../styles/project-tag.css'

export default function ProjectTag({ name }) {
  return (
    <div className='tag-container'>
        <p className='tag-name'>{name}</p>
    </div>
  )
}